import React from 'react';
import Layout from '../components/layout';
import Contact from '../components/contact/Contact';

export default function contact() {
  return (
      <Layout>
        <Contact />
      </Layout>
  );
}
