import React from 'react';
import globalStyles from '../styles/Main.module.css';
import styles from '../styles/Contact.module.css';

export default function Contact() {
  return (
    <div id={styles.container} className={globalStyles.section}>
      <h1 className={globalStyles.sectionTitle}>Contact Us</h1>
      <h3 id={styles.contactText}>
        Email: <a href="mailto:info@woodspond.org">info@woodspond.org</a>
        <br />
        <br />
        <b>Mailing Address:</b>
        <br />
        <br />
        PO Box 485 Bridgton, ME 04009
      </h3>
    </div>
  );
}
